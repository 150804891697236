<template>
  <section id="hero">
    <v-fade-transition mode="out-in">
      <v-banner v-if="!consent">
        <base-body>
          This website uses cookies to ensure you get the best experience on
          our website. For more info please read our
          <a
            href="https://www.cookiepolicygenerator.com/live.php?token=KGtXW7bnxsJLL3SgmVSYvYxafgGmW5ZF"
          >cookie policy</a>
        </base-body>
        <template v-slot:actions>
          <v-btn
            text
            color="primary"
            @click="setCookieConsent()"
          >
            Agree And Close
          </v-btn>
        </template>
      </v-banner>
    </v-fade-transition>
    <v-img
      :min-height="minHeight"
      :src="require('@/assets/simpleLogo.jpg')"
      class="white--text"
      :gradient="
        $vuetify.theme.isDark
          ? 'to bottom, rgba(33, 33, 33, .9), rgba(0,0,0,.4)'
          : 'to bottom, rgba(33, 118, 174, .75), rgba(0,0,0,.01)'
      "
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="1000"
          width="100%"
        >
          <base-subheading
            weight="regular"
            title=" Your night is a journey, not a destination"
          />

          <base-heading
            size="text-h2"
            title="WELCOME TO CORK PUB TRAILS"
            weight="medium"
          />
          <div
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'flex-column align-start'
                : 'align-center'
            "
            class="d-flex flex-wrap"
          >
            <base-btn @click="$router.push({ name: 'Trails' })">
              Find a trail
            </base-btn>

            <span
              class="font-weight-bold ml-6 mr-6"
              :class="$vuetify.breakpoint.smAndDown ? 'my-2' : 'my-4'"
            >
              or
            </span>

            <base-btn
              :ripple="false"
              class="pa-1 accent"
              @click="$router.push({ name: 'Discover' })"
            >
              Discover Pubs
            </base-btn>
          </div>
          <base-map class="mt-4" />
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  import BaseMap from '../../components/map/BaseMap.vue'
  import { mapActions } from 'vuex'
  export default {
    name: 'SectionHero',
    components: { BaseMap },
    data: () => ({
      consent: false,
    }),
    provide: {
      theme: { isDark: true },
    },
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    mounted () {
      this.updateSelectedTrail(null)
      this.consent = localStorage.getItem('consent')
    },
    methods: {
      ...mapActions(['updateSelectedTrail']),
      setCookieConsent () {
        this.consent = true
        localStorage.setItem('consent', true)
      },
    },
  }
</script>

<style>
@import "https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css";
</style>
